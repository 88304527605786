export const Paths = {
    Home: { path: "/" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    About: { path: "/about" },
    Signin: { path: "/signin" },
    Signup: { path: "/signup" },
    Profile: { path: "/profile" },
    Settings: { path: "/settings" },
    Billing: { path: "/billing" },
    Pricing: { path: "/pricing" },
    Notifications: { path: "/notifications" },
    VerifyEmail: { path: "/verify-email" },
    Report: { path: "/analysis" },
    Reports: { path: "/analyses" },
    NewReport: {path: "/new-analysis"},
    Alerts: {path: "/alerts"},
    NewAlert: {path: "/new-alert"},
    Terms: {path: "/terms"},
    Privacy: {path: "/privacy"},
    Goodbye: {path: "/goodbye"},
    Blog: {path: "/blog"},
    BlogPosts: {path: "/blog/posts"}
};
