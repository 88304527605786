import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { FaShare } from 'react-icons/fa';
import Page from "components/page";
import getBackend from 'backend/backend';
import { Paths } from "paths";

const BlogPost = () => {
    const [t] = useTranslation('common');
    const { post_id } = useParams();
    const [post, setPost] = useState({title: t('Loading...'), content: t('Loading...'), date: t('Loading...')});

    useEffect(() => {
        let canceled = false;

        const getBlogPost = async () => {
            const result = await getBackend().getBlogPost(post_id);
            if (canceled)
                return;

            if (result.error == null) {
                setPost(result.response.post);
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getBlogPost();

        return async () => {
            canceled = true;
        }
    }, [post_id]);

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
            title: post.title,
            text: 'Check out this blog post!',
            url: window.location.href
            });
        } else {
            // Fallback - copy to clipboard
            navigator.clipboard.writeText(window.location.href);
        }
    };

    return (
        <Page title={post.title} description={post.title}>
            <Container className="min-vh-100 my-5">
                <Row className="justify-content-md-center mb-4">
                    <Col md={8}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1>{post.title}</h1>
                        <Button variant="outline-primary" onClick={handleShare}>
                        <FaShare className="me-2" />
                        {t('Share')}
                        </Button>
                    </div>
                    <div className="text-muted mb-4">
                        {new Date(post.date).toLocaleDateString()}
                    </div>
                    <div className="blog-content">
                        <ReactMarkdown>
                            {post.content}
                        </ReactMarkdown>
                    </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default BlogPost;