import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Page from "components/page";
import { Link } from 'react-router-dom';
import getBackend from 'backend/backend';
import { Paths } from "paths";

const Blog = () => {
    const [t] = useTranslation('common');

    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        let canceled = false;

        const getBlogPosts = async () => {
            const result = await getBackend().getBlogPosts();
            if (canceled)
                return;

            if (result.error == null) {
                setBlogPosts(result.response.posts);
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getBlogPosts();

        return async () => {
            canceled = true;
        }
    }, []);


    return (
    <Page title={t('Blog')} description={t('Blog')}>
        <Container className="min-vh-100 my-5">
            <Row className="justify-content-md-center mb-4">
                <Col>
                    <h1 className="mb-4">{t('Blog')}</h1>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={3} className="g-4">
                {blogPosts.map((post) => (
                    <Col key={post.id}>
                        <Card className="h-100">
                            <Card.Body>
                                <Card.Title>{post.title}</Card.Title>
                                <Card.Text>
                                    <small className="text-muted">
                                        {new Date(post.date).toLocaleDateString()}
                                    </small>
                                </Card.Text>
                                <Link to={`${Paths.BlogPosts.path}/${post.id}`} className="btn btn-primary">
                                    {t('Read More')}
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    </Page>
    );
};

export default Blog;